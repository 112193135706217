.ant-popover .ant-popover-inner {
  padding: 0;
}
.ant-popover-inner-content {
  padding: 0;
  margin: 0;
}
.notification-item {
  background: #ffffff;
  border-radius: 8px;
  height: 135px;
  width: 100%;
  display: flex;
  padding-top: 9px;
  padding-bottom: 14px;
  margin-bottom: 14px;
  position: relative;
}

.notification-item .notification-left {
  width: 32px;
}

.notification-item .notification-unread {
  position: absolute;
  left: 13px;
  top: 16.5px;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background: linear-gradient(276.69deg, #bf2e1a 10.12%, #932010 87.52%);
}

.notification-item .notification-right {
  padding-right: 20px;
}

.notification-item .notification-header {
  display: flex;
  justify-content: space-between;
}

.notification-item .notification-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.notification-item .notification-time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #696f8c;
}

.notification-item .notification-body {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
}

.notification-loading {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
