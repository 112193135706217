.layout {
  background: radial-gradient(50% 50% at 50% 50%, #ddd 0%, #fff 100%);
  background-size: "cover";
}

.container {
  max-width: 408px;
  margin: auto;
  height: 290px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 33px;
  padding-bottom: 70px;
  box-shadow: 0px 12px 40px rgba(16, 24, 64, 0.24);
  border-radius: 10px;
}

.container .logo {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #bf2e1a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.title {
  text-align: center;
  color: #626262;
  font-size: 30px;
  letter-spacing: -0.04em;
  margin-top: 20px;
}

.okta-button {
  width: 153px;
  background: #1a1e3c;
  border-radius: 4px;
}

.okta-button:hover,
.okta-button:focus {
  background: #1a1e3c;
  opacity: 0.9;
  border: none;
}
