.badge-select-item {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.badge-select-item__active {
  border: 2px solid #000000;
  border-radius: 5px;
}

.badge-select-item:hover {
  border: 2px solid rgb(156 163 175);
  border-radius: 5px;
}

.badge-empty {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f7f9;
  border: 1px dashed #e6e3e2;
  border-radius: 5px;
}
